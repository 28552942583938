import { Hooks, Layout } from '@cfra-nextgen-frontend/shared';
import { ETFPageContainer } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFPageContainer/ETFPageContainer';
import {
    FormatValueParams,
    ScrollingContext,
    animateScrollTo,
    animateScrollToTop,
    assetClasses,
    formatValue,
    getDataPointDisplayNameToFieldName,
    getDataPointsDisplayNameToFormattingType,
    getDataPointsDisplayNames,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import TrendingUpCircle from 'assets/images/TrendingUpCircle.svg';
import { throttle } from 'lodash';
import { Children, useContext, useEffect, useRef, useState } from 'react';
import { prefetchHelper } from 'utils';
import { Component, TabName } from '../types/components';
import { ETFDetailsParams, EtfDetailsData } from '../types/research';
import CreditExposure from './CreditExposure';
import CreditExposureHistorical from './CreditExposureHistorical';
import ETFFlows from './ETFFlows';
import ETFFlowsVSMarketMovement from './ETFFlowsVSMarketMovement';
import ETFPerformance from './ETFPerformance';
import FundDetail from './FundDetail';
import KeyStatistic from './KeyStatistic';
import MaturityExposure from './MaturityExposure';
import Overview from './Overview';
import SectorExposure from './SectorExposure';
import SectorExposureHistorical from './SectorExposureHistorical';
import TopHoldings from './TopHoldings';
import CFRARatings from './CFRARatings';

const mainHeaderElementHeight = 70;

export default function ETFDetailTabs({
    latestUpdatesQuery,
    companyData,
}: {
    latestUpdatesQuery: EtfDetailsData;
    companyData: ETFDetailsParams;
}) {
    const theme = useTheme();
    const [componentRendered, setComponentRendered] = useState<boolean>(false);
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const ref = useRef<HTMLDivElement[]>([]);
    const tickerHeaderElementHeight = Hooks.useHeightByElementId('etf-ticker-header');

    const { goingUp, setGoingUp, activeTab, setActiveTab } = useContext(ScrollingContext);
    setTimeout(() => (globalThis.cfraData.pageData.section = Object.values(TabName)[activeTab]), 300); // we need some time to let registerAction finish work it it was triggered simultaneously with registerPageView

    const prevScrollY = useRef(0);

    const onScroll = () => {
        const currentScrollY = window.scrollY;

        if (prevScrollY.current < currentScrollY && goingUp) {
            setGoingUp(false);
        }
        if (prevScrollY.current > currentScrollY && !goingUp) {
            setGoingUp(true);
        }

        const { top, height } = ref.current[Number(matches)].getBoundingClientRect();
        const absoluteElementCenter = top + height / 2 + window.pageYOffset;
        const middle = Math.floor(absoluteElementCenter - window.innerHeight / 2);

        const handleScroll = (tabIndex: number) => {
            globalThis.analytics?.registerAction?.({
                action: `scroll to ${Object.values(TabName)[tabIndex]}`,
            });
            setActiveTab(tabIndex);
        };

        if (middle < currentScrollY && !goingUp && activeTab !== 1) {
            handleScroll(1);
        }
        if (middle >= currentScrollY && goingUp && activeTab !== 0) {
            handleScroll(0);
        }

        prevScrollY.current = currentScrollY;
    };

    var throttledFunc = throttle(onScroll, 100, { leading: true });

    useEffect(() => {
        window.addEventListener('scroll', throttledFunc, { passive: true });
        return () => {
            window.removeEventListener('scroll', throttledFunc);
        };
    }, [activeTab, goingUp, throttledFunc]);

    useEffect(() => {
        prefetchHelper.runWhenNoSkeletons(() => setComponentRendered(true));
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const scrollToTop = () => {
        animateScrollToTop();
        setGoingUp(true);
    };

    const scrollToDivider = () => {
        const { top, height } = ref.current[Number(matches)].getBoundingClientRect();
        const absoluteElementCenter = top + height / 2 + window.pageYOffset;
        const middle = Math.floor(absoluteElementCenter - window.innerHeight / 2);
        animateScrollTo(middle);
        if (window.scrollY < middle) setGoingUp(false);
        else setGoingUp(true);
    };

    // get display names list for all data points
    const dataPointsDisplayNames = getDataPointsDisplayNames();
    // get matching between display names and object property name
    const dataPointDisplayNameToFieldName = getDataPointDisplayNameToFieldName();
    // get matching between display names and value formatting type
    const dataPointsDisplayNameToFormattingType = getDataPointsDisplayNameToFormattingType();
    // create instance of class FormatValueParams
    const formatValueParams = new FormatValueParams({
        source: latestUpdatesQuery,
        dataPointDisplayNameToFieldName: dataPointDisplayNameToFieldName,
        dataPointsDisplayNameToFormattingType: dataPointsDisplayNameToFormattingType,
    });

    const cfraId = formatValue(formatValueParams.create({ key: dataPointsDisplayNames.CfraID })) as string;

    const getRenderedDivider = (ref: (element: HTMLDivElement) => void) => (
        <Layout.ETFDivider
            imageSource={TrendingUpCircle}
            header='Flows and Performance'
            description='The charts below highlight historical net flows, total returns, and an analysis of fund asset level changes over time.'
            ref={ref}
        />
    );

    const componentMapping: Record<Component, JSX.Element> = {
        [Component.Overview]: <Overview etfDetailsData={latestUpdatesQuery} />,
        [Component.SectorExposureOverTime]: (
            <SectorExposureHistorical companyData={companyData} componentRendered={componentRendered} />
        ),
        [Component.SectorExposure]: (
            <SectorExposure cfraId={cfraId} companyData={companyData} componentRendered={componentRendered} />
        ),
        [Component.TopHoldings]: <TopHoldings etfDetailsData={latestUpdatesQuery} cfraId={cfraId} />,
        [Component.MaturityExposure]: (
            <MaturityExposure cfraId={cfraId} companyData={companyData} componentRendered={componentRendered} />
        ),
        [Component.CreditExposure]: (
            <CreditExposure cfraId={cfraId} companyData={companyData} componentRendered={componentRendered} />
        ),
        [Component.CreditExposureOvertime]: (
            <CreditExposureHistorical companyData={companyData} componentRendered={componentRendered} />
        ),
        [Component.SectionDividerFlowsPerformance]: getRenderedDivider((element) => {
            if (element) ref.current[0] = element;
        }),
        [Component.FundFlows]: (
            <ETFFlows cfraId={cfraId} companyData={companyData} componentRendered={componentRendered} />
        ),
        [Component.FundVsMarketMovement]: (
            <ETFFlowsVSMarketMovement cfraId={cfraId} companyData={companyData} componentRendered={componentRendered} />
        ),
        [Component.ETFPerformance]: <ETFPerformance etfDetailsData={latestUpdatesQuery} cfraId={cfraId} />,
        [Component.KeyStatistics]: <KeyStatistic etfDetailsData={latestUpdatesQuery} />,
        [Component.FundDetails]: <FundDetail detail={latestUpdatesQuery.etf_description} />,
        [Component.CFRARatings]: <CFRARatings cfraId={cfraId} />
    };

    const defaultSingleColumnLayout: Record<TabName, Component[]> = {
        [TabName.HoldingsAndExposure]: [
            Component.FundDetails,
            Component.CFRARatings,
            Component.TopHoldings,
            Component.SectorExposureOverTime,
            Component.SectorExposure,
        ],
        [TabName.FlowsAndPerformance]: [
            Component.FundFlows,
            Component.FundVsMarketMovement,
            Component.KeyStatistics,
            Component.ETFPerformance,
            Component.Overview,
        ],
    };

    const defaultMultipleColumnsLayout: Record<number, Record<TabName, Component[]>> = {
        0: {
            [TabName.HoldingsAndExposure]: [
                Component.TopHoldings,
                Component.CFRARatings,
                Component.SectorExposureOverTime,
                Component.SectorExposure,
                Component.SectionDividerFlowsPerformance,
                Component.ETFPerformance,
                Component.FundFlows,
            ],
            [TabName.FlowsAndPerformance]: [],
        },
        1: {
            [TabName.HoldingsAndExposure]: [Component.Overview, Component.KeyStatistics, Component.FundDetails],
            [TabName.FlowsAndPerformance]: [],
        },
    };

    const singleColumnLayout: Record<TabName, { [key in assetClasses]?: Component[] }> = {
        [TabName.HoldingsAndExposure]: {
            [assetClasses.EquitiesStocks]: [
                Component.FundDetails,
                Component.CFRARatings,
                Component.SectorExposureOverTime,
                Component.SectorExposure,
                Component.TopHoldings,
            ],
            [assetClasses.Bonds]: [
                Component.FundDetails,
                Component.CFRARatings,
                Component.CreditExposureOvertime,
                Component.CreditExposure,
                Component.MaturityExposure,
                Component.TopHoldings,
            ],
        },
        [TabName.FlowsAndPerformance]: {
            [assetClasses.EquitiesStocks]: [
                Component.FundFlows,
                Component.FundVsMarketMovement,
                Component.ETFPerformance,
                Component.KeyStatistics,
                Component.Overview,
            ],
            [assetClasses.Bonds]: [
                Component.FundFlows,
                Component.FundVsMarketMovement,
                Component.KeyStatistics,
                Component.ETFPerformance,
                Component.Overview,
            ],
        },
    };

    const multipleColumnsLayout: Record<number, Record<TabName, { [key in assetClasses]?: Component[] }>> = {
        0: {
            [TabName.HoldingsAndExposure]: {
                [assetClasses.EquitiesStocks]: [
                    Component.CFRARatings,
                    Component.SectorExposureOverTime,
                    Component.SectorExposure,
                    Component.TopHoldings,
                ],
                [assetClasses.Bonds]: [
                    Component.CFRARatings,
                    Component.CreditExposureOvertime,
                    Component.CreditExposure,
                    Component.MaturityExposure,
                    Component.TopHoldings,
                ],
            },
            [TabName.FlowsAndPerformance]: {
                [assetClasses.EquitiesStocks]: [Component.FundFlows],
                [assetClasses.Bonds]: [Component.FundFlows],
            },
        },
        1: {
            [TabName.HoldingsAndExposure]: {
                [assetClasses.EquitiesStocks]: [Component.Overview, Component.KeyStatistics, Component.FundDetails],
            },
            [TabName.FlowsAndPerformance]: {
                [assetClasses.EquitiesStocks]: [Component.ETFPerformance],
                [assetClasses.Bonds]: [Component.ETFPerformance],
            },
        },
    };

    const singleColumnComponents = (tabName: TabName, assetClass: assetClasses) => {
        return Children.toArray(
            (singleColumnLayout[tabName][assetClass] ?? defaultSingleColumnLayout[tabName]).map(
                (component: Component) => componentMapping[component],
            ),
        );
    };

    const multipleColumnsComponents = (tabName: TabName, assetClass: assetClasses, column: number) => {
        return Children.toArray(
            (multipleColumnsLayout[column][tabName][assetClass] ?? defaultMultipleColumnsLayout[column][tabName]).map(
                (component: Component) => componentMapping[component],
            ),
        );
    };

    const isScreenDisplay = useMediaQuery('screen');

    return (
        <>
            <Layout.ETFHeaderContainer
                shadow
                sx={{
                    zIndex: 1100,
                    position: isScreenDisplay ? 'sticky' : 'static',
                    top: mainHeaderElementHeight + tickerHeaderElementHeight,
                }}>
                <Layout.StyledTabs value={activeTab} onChange={handleChange} aria-label='etf tabs'>
                    <Layout.StyledTab
                        label={TabName.HoldingsAndExposure}
                        value={0}
                        onClick={() => {
                            scrollToTop();
                            globalThis.analytics?.registerAction?.({
                                action: `section tabs : ${TabName.HoldingsAndExposure}`,
                            });
                        }}
                    />
                    {/* getSection() function on etf details page have dependency on FlowsAndPerformance tab */}
                    <Layout.StyledTab
                        label={TabName.FlowsAndPerformance}
                        value={1}
                        onClick={() => {
                            scrollToDivider();
                            globalThis.analytics?.registerAction?.({
                                action: `section tabs : ${TabName.FlowsAndPerformance}`,
                            });
                        }}
                    />
                </Layout.StyledTabs>
            </Layout.ETFHeaderContainer>
            <ETFPageContainer>
                <Layout.ETFTabContainer>
                    {matches ? (
                        <Grid container spacing={3}>
                            <Grid container item xs={12}>
                                {singleColumnComponents(TabName.HoldingsAndExposure, latestUpdatesQuery.asset_class)}
                                {getRenderedDivider((element) => {
                                    if (element) ref.current[1] = element;
                                })}
                                {singleColumnComponents(TabName.FlowsAndPerformance, latestUpdatesQuery.asset_class)}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                {multipleColumnsComponents(
                                    TabName.HoldingsAndExposure,
                                    latestUpdatesQuery.asset_class,
                                    0,
                                )}
                            </Grid>
                            <Grid item md={4}>
                                {multipleColumnsComponents(
                                    TabName.HoldingsAndExposure,
                                    latestUpdatesQuery.asset_class,
                                    1,
                                )}
                            </Grid>
                            {[assetClasses.EquitiesStocks, assetClasses.Bonds].includes(
                                latestUpdatesQuery.asset_class,
                            ) && (
                                <Grid item md={12}>
                                    {componentMapping[Component.SectionDividerFlowsPerformance]}
                                </Grid>
                            )}
                            <Grid item md={8}>
                                {multipleColumnsComponents(
                                    TabName.FlowsAndPerformance,
                                    latestUpdatesQuery.asset_class,
                                    0,
                                )}
                            </Grid>
                            <Grid item md={4}>
                                {multipleColumnsComponents(
                                    TabName.FlowsAndPerformance,
                                    latestUpdatesQuery.asset_class,
                                    1,
                                )}
                            </Grid>
                            <Grid item md={12}>
                                {componentMapping[Component.FundVsMarketMovement]}
                            </Grid>
                        </Grid>
                    )}
                </Layout.ETFTabContainer>
            </ETFPageContainer>
        </>
    );
}
