import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { RoundedTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/RoundedTextButton';
import { StyledFormLabel } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { ScreenerAnalyticsServiceContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import {
    SavedItemOperations,
    getSelectedItem,
    getWatchlistEtfsData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { SaveScreenContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/saveScreenerContext/Context';
import { WatchListContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { WatchListRow } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { Autocomplete, Box, TextField, ThemeProvider } from '@mui/material';
import { getSavedItemById, getSavedItemsByType, updateSavedItemById } from 'features/etfScreener/api/userSavedItems';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Theme, getSavedWatchLists, getWatchListData, roundedTextButtonTheme } from './WatchListUtils';
import './snackBar.scss';

export default function UpdateWatchListComponent({
    registerAnalytics,
    onIsDirtyChange,
    onSuccessCallback,
    handleDuplicateIds,
    externalViewWatchlist,
    watchListData,
    modalTitle,
}: {
    registerAnalytics: (action: string) => void;
    onIsDirtyChange: (v: boolean) => void;
    onSuccessCallback?: () => void;
    handleDuplicateIds?: boolean;
    externalViewWatchlist?: boolean;
    watchListData?: Array<WatchListRow>;
    modalTitle: string;
}) {
    const {
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);
    const { saveScreenActionDispatcher } = useContext(SaveScreenContext);
    const { setSearchTerm } = useContext(FiltersModalContext);
    const { watchListDispatcher } = useContext(WatchListContext);
    const { selectedRowsData } = useContext(AgGridSelectedRowsContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [updateWatchlist, setUpdateWatchlist] = useState({ id: undefined, name: '' });
    const [watchlistData, setWatchlistData] = useState<undefined | WatchListRow[]>(undefined);
    const { registerAction } = useContext(ScreenerAnalyticsServiceContext);

    const navigate = useNavigate();
    const navigateToScreener: (data: Object) => void = useCallback(
        (data) =>
            navigate('/screener', {
                state: {
                    setSelectedWatchlist: data,
                },
            }),
        [navigate],
    );

    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const getWatchlistByIdQry = getSavedItemById({
        savedItems: updateWatchlist.id || -1,
        config: { enabled: typeof updateWatchlist.id === 'number' },
    });

    const enableUpdateWatchlist = watchlistData !== undefined && typeof updateWatchlist.id === 'number';

    const updateWatchListQueryResult = updateSavedItemById({
        savedItems: updateWatchlist.id || -1,
        requestBody: {
            name: updateWatchlist.name,
            value: {
                values: watchlistData,
            },
        },
        config: {
            enabled: enableUpdateWatchlist,
        },
    });
    const getAllWatchLists = getSavedItemsByType({ types: [UserSavedItemsLookupID.Watchlist] });

    useEffect(() => {
        if (updateWatchListQueryResult.isSuccess && updateWatchlist.name && enableUpdateWatchlist) {
            const analyticsEtfsData = getWatchlistEtfsData(
                (updateWatchListQueryResult.data.data.value as { values: WatchListRow[] }).values,
            );
            const analyticsSelectedEtfsData = getWatchlistEtfsData(
                watchListData === undefined
                    ? selectedRowsData.map((item) => ({
                          composite_ticker: item?.fund_profile?.composite_ticker,
                          composite_name: item?.fund_profile?.composite_name,
                          id: item?.fund_profile?.identifiers?.cfra_id || item?.id,
                      }))
                    : watchListData,
            );

            const savedItemOperation = SavedItemOperations.AddToExistingWatchlist;

            const registerActionParams = {
                action: `${savedItemOperation} : ${getSelectedItem(updateWatchListQueryResult.data.data)}`,
                cardName: modalTitle,
                savedItemName: updateWatchListQueryResult.data.data.name,
                savedItemId: updateWatchListQueryResult.data.data.id,
                savedItemOperation: savedItemOperation,
                savedItemLength: `${analyticsSelectedEtfsData.length} : ${analyticsEtfsData.length}`,
                list1: analyticsEtfsData,
                list2: analyticsSelectedEtfsData,
            };

            if (registerAction) {
                registerAction(registerActionParams);
            } else {
                globalThis.analytics?.registerAction?.(registerActionParams);
            }

            onSuccessCallback?.();
            const snackbarKey = ShowSnack(
                SnackMessageForm({
                    message: `You added tickers to ${updateWatchlist.name} watchlist`,
                    actionBtnText: 'View Watchlist',
                    onActionBtnClick: () => {
                        if (updateWatchListQueryResult?.data?.data) {
                            onChipClearAllClick();

                            if (saveScreenActionDispatcher !== undefined)
                                saveScreenActionDispatcher({ type: 'ClearSelectedScreen' });

                            if (setSearchTerm !== undefined) setSearchTerm('');

                            watchListDispatcher({
                                type: 'SetSelectedWatchlist',
                                payload: updateWatchListQueryResult?.data?.data,
                            });

                            if (snackbarKey) {
                                closeSnackbar(snackbarKey);
                            }

                            if (externalViewWatchlist) {
                                navigateToScreener(updateWatchListQueryResult?.data?.data);
                            }
                        }
                    },
                }),
            );
            getAllWatchLists.refetch();
            watchListDispatcher({ type: 'CloseSaveWatchlistModal' });
        }
    }, [
        updateWatchListQueryResult,
        watchListDispatcher,
        enqueueSnackbar,
        updateWatchlist.name,
        getAllWatchLists,
        ShowSnack,
        enableUpdateWatchlist,
        onChipClearAllClick,
        saveScreenActionDispatcher,
        setSearchTerm,
        closeSnackbar,
        onSuccessCallback,
        navigateToScreener,
        externalViewWatchlist,
        modalTitle,
        registerAction,
        selectedRowsData,
        watchListData,
    ]);

    const savedWatchLists = getSavedWatchLists(getAllWatchLists);

    const UpdateWatchListData = (e: any, newValue: any) => {
        setUpdateWatchlist({
            name: (newValue && newValue.name) || undefined,
            id: (newValue && newValue.id) || undefined,
        });
        onIsDirtyChange(e);
    };

    async function handleOnUpdateClick() {
        const { isRefetching, isRefetchError, data } = await getWatchlistByIdQry.refetch();
        if (!isRefetching && !isRefetchError && data) {
            const values: Array<WatchListRow> = data?.data?.value?.values || [];
            let newSelectedValues = watchListData === undefined ? getWatchListData(selectedRowsData) : watchListData;

            if (handleDuplicateIds) {
                const duplicateIds: Array<string> = [];
                const existingIds: Array<string> = values.map((obj) => obj.id);

                newSelectedValues.forEach((obj) => {
                    if (existingIds.includes(obj.id)) {
                        const snackbarKey = ShowSnack(
                            SnackMessageForm({
                                message: `${obj.composite_ticker} is already in ${updateWatchlist.name}. Please select a different Watchlist.`,
                                onActionBtnClick: () => {
                                    if (snackbarKey) {
                                        closeSnackbar(snackbarKey);
                                    }
                                },
                            }),
                        );

                        duplicateIds.push(obj.id);
                    }
                });

                duplicateIds.forEach(
                    (id) => (newSelectedValues = newSelectedValues.filter((value) => value.id !== id)),
                );

                if (newSelectedValues.length === 0) {
                    return;
                }
            }

            const mergedArray: Array<WatchListRow> = [...values, ...newSelectedValues];

            const uniqueObjects = mergedArray.reduce((acc: { [key: string]: WatchListRow }, obj) => {
                const keyValue = obj['id'];
                if (!acc[keyValue]) {
                    acc[keyValue] = obj;
                }
                return acc;
            }, {});

            setWatchlistData(Object.values(uniqueObjects));
        }
    }

    return (
        <>
            <Box sx={{ paddingTop: '20px' }}>
                <StyledFormLabel sx={{ fontSize: '16px', mt: '6px', mb: '10px' }}>My Watchlists</StyledFormLabel>
                <ThemeProvider theme={Theme}>
                    <Autocomplete
                        options={savedWatchLists}
                        disableListWrap
                        slotProps={{
                            popper: {
                                sx: { zIndex: 3000 },
                            },
                        }}
                        onChange={UpdateWatchListData}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} placeholder='Select' />}
                        disabled={getWatchlistByIdQry.isLoading}
                    />
                </ThemeProvider>
            </Box>
            <Box sx={{ paddingTop: '33px', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <RoundedTextButton
                    type='submit'
                    theme={roundedTextButtonTheme}
                    buttonText={'Update'}
                    disabled={typeof getWatchlistByIdQry.data?.data?.id !== 'number'}
                    onClickCallback={() => {
                        registerAnalytics('click on Save');
                        handleOnUpdateClick();
                    }}
                />
            </Box>
        </>
    );
}
