import FormAutocompleteSearchTable from '@cfra-nextgen-frontend/shared/src/components/Form/FormAutocompleteSearchTable';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { getFilterLabel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/shared';
import { FilterProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/types';
import {
    componentToFilterDivider,
    replaceAllDotsWithTabs,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';

export const ScreenerFormAutocompleteSearchTable: React.FC<FilterProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    submitHandler,
    hide,
    SearchComponent,
    parentSectionKey,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    return (
        <>
            {SearchComponent}
            <FormAutocompleteSearchTable
                label={label}
                hide={hide}
                control={control}
                name={`${Components.AutocompleteSearchTable}${componentToFilterDivider}${replaceAllDotsWithTabs(
                    filterMetadataKey,
                )}`}
                options={filtersData.data[filterMetadataKey]?.items}
                placeholder={filterMetadata.placeholder || ''}
                defaultValues={filterMetadata.default_values}
                submitHandler={submitHandler}
            />
        </>
    );
};
