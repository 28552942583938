import { ETFCard, ETFLinkButton, Layout } from '@cfra-nextgen-frontend/shared';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal/index';
import { useCallback } from 'react';

export function ReadMore(props: { text: string, modalTitle: string, textLengthToShowReadMore?: number}) {

    const { text, modalTitle, textLengthToShowReadMore = 500 } = props;
    const getOpenModalButton = useCallback((handleOpen: () => void) => {
        return <ETFLinkButton onClick={handleOpen} text='... Read More' />;
    }, []);

    return (
        <ETFCard.ItemDescription>
            {text.length <= textLengthToShowReadMore ? (
                text
            ) : (
                <>
                    {text.substring(0, text.lastIndexOf(' ', textLengthToShowReadMore) + 1)}
                    <ETFModal
                        getOpenComponent={getOpenModalButton}
                        title={modalTitle}
                        description={text}
                        modalBoxStyles={{
                            display: 'block',
                            maxWidth: '1400px',
                            maxHeight: 'none',
                            overflowY: 'hidden',
                        }}
                    />
                </>
            )}
        </ETFCard.ItemDescription>
    );
}