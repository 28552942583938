import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { fontFamilies, fontWeights } from '@cfra-nextgen-frontend/shared/src/utils';
import { ThemeProvider } from '@emotion/react';
import { SxProps, TextField, createTheme } from '@mui/material';
import { Controller } from 'react-hook-form';
import { inputBaseStyles } from './FormTextField';
import { CommonFormComponentProps } from './types/form';

type FormControlledTextFieldProps = {
    placeholder: string;
    defaultValue: string;
    validationRules?: any;
    fieldDefaultStyles?: SxProps;
} & CommonFormComponentProps;

export const errorMessageStyles = {
    color: '#D80027',
    margin: '1px 0px',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: '13px',
    fontWeight: fontWeights.Regular,
    fontStyle: 'normal',
    minHeight: '13px',
};

export const textFieldTheme = createTheme({
    components: {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...inputFontStyle,
                },
                input: {
                    ...inputBaseStyles,
                    '&::placeholder': {
                        ...inputFontStyle,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: '#0B2958',
                    '& .Mui-error': {
                        color: '#D80027',
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    ...errorMessageStyles,
                },
            },
        },
    },
});

export function FormControlledTextField(props: FormControlledTextFieldProps) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue}
            rules={props.validationRules}
            render={({ field, fieldState: { error } }) => (
                <ThemeProvider theme={textFieldTheme}>
                    <TextField
                        {...field}
                        type='text'
                        placeholder={props.placeholder}
                        error={Boolean(error?.message)}
                        helperText={Boolean(error?.message) ? error?.message : ''}
                        onChange={(e) => {
                            field.onChange(e.target.value);
                            if (Boolean(error?.message)) {
                                props.submitHandler?.();
                            }
                        }}
                        inputProps={{
                            style: { fontSize: inputFontStyle.fontSize },
                        }}
                        onBlur={(_) => {
                            props.submitHandler?.();
                        }}
                        autoComplete='off'
                        size='small'
                        sx={{ minWidth: '125px', maxWidth: '275px', ...props.fieldDefaultStyles }}
                    />
                </ThemeProvider>
            )}
        />
    );
}