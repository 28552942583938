import { FilterMetadata } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';

export function getFilterLabel(filterMetadata: FilterMetadata, parentSectionKey: string) {
    const label = filterMetadata.label || filterMetadata.item_metadata.label;

    if (!Object.keys(filterMetadata.sections).includes(parentSectionKey)) {
        throw new Error(
            `getFilterLabel exception. Parent section key '${parentSectionKey}' not found in filter metadata`,
        );
    }

    const sections = filterMetadata.sections;
    const show_asterisk = sections[parentSectionKey].show_asterisk;

    return `${label}${show_asterisk ? '*' : ''}`;
}

export const stackLabelWithContainerProps = { disableStacking: true, labelLayout: 12, containerLayout: 12 };
