import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { componentToFilterDivider, defaultStarsRatingLength, replaceAllDotsWithTabs } from '../utils';
import { FilterProps } from './types';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { useMediaQuery } from '@mui/material';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import FormStarsRating from '@cfra-nextgen-frontend/shared/src/components/Form/FormStarsRating';

export default function ScreenerFormRating({
    filtersData,
    filterMetadataKey,
    control,
    layoutProps,
    getValues,
    setValue,
    submitHandler,
    hide,
}: FilterProps) {
    const isAboveMd = useMediaQuery(breakpointsTheme.breakpoints.up('md'));
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const label = typeof filterMetadata.label === 'string' ? filterMetadata.label : filterMetadata.item_metadata.label;

    const filter = (
        <FormStarsRating
            name={`${Components.Rating}${componentToFilterDivider}${replaceAllDotsWithTabs(filterMetadataKey)}`}
            control={control}
            submitHandler={submitHandler}
            getValues={getValues}
            setValue={setValue}
        />
    );

    return (
        <FormLabelWithContainer
            hide={hide}
            label={label}
            layoutProps={layoutProps}
            labelLayout={isAboveMd ? 2.5 : 5}
            wrapperStyles={{ justifyContent: 'flex-start' }}
            containerLayout={isAboveMd ? 9 : 7}>
            {filter}
        </FormLabelWithContainer>
    );
}
