import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { Footer } from '@cfra-nextgen-frontend/shared/src/components/Footer';
import { PageWithBreadcrumbsInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader';
import { ContentWrapper } from '@cfra-nextgen-frontend/shared/src/components/PageWithBreadcrumbsInHeader/PageWithBreadcrumbsInHeader';
import { WatchListContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { Link } from '@mui/material';
import { Page } from 'analytics/Page';
import ThematicImg1 from 'assets/images/thematic/1. Income Oriented ETFs v2.jpg';
import ThematicImg10 from 'assets/images/thematic/10. ETFs with Trending Investor Activity v2.jpg';
import ThematicImg2 from 'assets/images/thematic/2. Crypto and Blockchain.svg';
import ThematicImg3 from 'assets/images/thematic/3. Active Thematic and Factor ETFs with significant recent inflows v2.jpg';
import ThematicImg4 from 'assets/images/thematic/4. ETFs for an Inflationary environment v2.jpg';
import ThematicImg5 from 'assets/images/thematic/5. Defensive Equity ETFs.svg';
import ThematicImg6 from 'assets/images/thematic/6. Supply Chain reconfiguration v2.jpg';
import ThematicImg7 from 'assets/images/thematic/7. Climate Transition v2.jpg';
import ThematicImg8 from 'assets/images/thematic/8. Successful recent ETF launches v2.jpg';
import ThematicImg9 from 'assets/images/thematic/9. Future of Food and Agriculture v2.jpg';
import { getAllThematicThemes } from 'features/cfraInsights/api/thematicThemes';
import { Route, Link as RouterLink, Routes } from 'react-router-dom';
import { AppRoutePaths, PageNames } from 'utils/enums';
import { CFRAInsight } from '../components/CFRAInsight';
import { CFRAInsightsHome } from '../components/CFRAInsightsHome';

type LinkProps = {
    pathName: string;
    children: React.ReactNode;
    analyticsCallback?: () => void;
    detailsPathMapping: Record<string, string>;
};

const THEME_TO_IMAGE: { [key: string]: string } = {
    equity_income: ThematicImg1,
    crypto_and_blockchain: ThematicImg2,
    recent_inflows: ThematicImg3,
    etfs_for_an_inflationary_environment: ThematicImg4,
    quality_and_low_volatility_equities: ThematicImg5,
    supply_chain_reconfiguration: ThematicImg6,
    climate_transition: ThematicImg7,
    successful_recent_launches: ThematicImg8,
    future_of_food_and_agriculture: ThematicImg9,
    trending_investor_activity: ThematicImg10,
};

export function ThematicDetailsLink({ children, pathName, analyticsCallback, detailsPathMapping }: LinkProps) {
    return (
        <Link
            component={RouterLink}
            to={`./${detailsPathMapping[pathName]}`}
            onClick={analyticsCallback}
            underline={'none'}>
            {children}
        </Link>
    );
}

export function CFRAInsightsRoutes() {
    const allThemes = getAllThematicThemes({});

    if (allThemes.isLoading) {
        return (
            <ContentWrapper paddingTop={16} paddingBottom={80}>
                <ETFCard.ETFCard isLoading={allThemes.isLoading} />
            </ContentWrapper>
        );
    }

    if (!allThemes.data) {
        throw new Error('allThemes is empty.');
    }

    const detailsPathMapping: Record<string, string> = Object.entries(allThemes.data._themedata)
        .map(([key, value]) => ({
            [value.display_name]: key,
        }))
        .reduce(
            (accumulator, current) => ({
                ...accumulator,
                ...current,
            }),
            {},
        );

    const themeData = Object.entries(allThemes.data._themedata)
        .map(([key, value]) => {
            if (!Object.keys(THEME_TO_IMAGE).includes(key)) {
                throw new Error(`THEME_TO_IMAGE doesn't include image for theme with key - ${key}`);
            }
            return { [key]: { ...value, image_url: THEME_TO_IMAGE[key] } };
        })
        .reduce(
            (accumulator, current) => ({
                ...accumulator,
                ...current,
            }),
            {},
        );

    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={
                        <ContentWrapper paddingTop={16} paddingBottom={80}>
                            <Page name={PageNames.CfraInsights}>
                                <CFRAInsightsHome
                                    themeData={themeData}
                                    detailsPathMapping={detailsPathMapping}
                                    pageName={PageNames.CfraInsights}
                                />
                            </Page>
                        </ContentWrapper>
                    }
                />
                <Route
                    element={
                        <PageWithBreadcrumbsInHeader
                            pathMapping={{
                                ...detailsPathMapping,
                                [PageNames.CfraInsights]: AppRoutePaths.CfraInsights.replaceAll('/', ''),
                            }}
                            includeHeader={false}
                        />
                    }>
                    {Object.entries(detailsPathMapping).map(([key, value], index) => (
                        <Route
                            key={index}
                            path={value}
                            element={
                                <Page name={key.toLowerCase()} group='curate list details'>
                                    <WatchListContextProvider>
                                        <CFRAInsight insightDetails={themeData[value]} thematicScreen={value} />
                                    </WatchListContextProvider>
                                </Page>
                            }
                        />
                    ))}
                </Route>
            </Routes>
            <Footer />
        </>
    );
}
