import { FormDateRangePicker } from '@cfra-nextgen-frontend/shared/src/components/Form/FormDateRangePicker';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import {
    getFilterLabel,
    stackLabelWithContainerProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/shared';
import { FilterProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/types';
import {
    componentToFilterDivider,
    replaceAllDotsWithTabs,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { keyToDateRangePickerLabelValue } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { DateRange } from '@mui/x-date-pickers-pro';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { Dayjs } from 'dayjs';

export const ScreenerFormDateRangePicker: React.FC<FilterProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    getValues,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
}) => {
    const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
        ...filtersData.filter_metadata[filterMetadataKey].options.map((option) => ({
            label: option.label || keyToDateRangePickerLabelValue[option.key].label,
            getValue: keyToDateRangePickerLabelValue[option.key].getValue,
        })),
        { label: 'Reset', getValue: () => [null, null] },
    ];

    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const stackLabelWithContainer = filterMetadata.sections[parentSectionKey].stack_label_and_filter;
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    const filter = (
        <FormDateRangePicker
            name={`${Components.DateRangePicker}${componentToFilterDivider}${replaceAllDotsWithTabs(
                filterMetadataKey,
            )}`}
            control={control}
            getValues={getValues}
            submitHandler={submitHandler}
            shortcutsItems={shortcutsItems}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
