import { Dispatch, createContext, useEffect, useMemo, useState } from 'react';
import { FiltersData } from '../../Form/types/filters';

type FiltersModalState = {
    openFiltersModal: boolean;
    setOpenFiltersModal: Dispatch<boolean>;
    filtersPostData: Object | undefined;
    setFiltersPostData: Dispatch<Object | undefined>;
    filtersMetadata: FiltersData | undefined;
    setFiltersMetadata: Dispatch<FiltersData | undefined>;
    activeIndex: Record<string, number>;
    setActiveIndex: Dispatch<Record<string, number>>;
    searchTerm: string;
    setSearchTerm: Dispatch<string>;
};

export const FiltersModalContext = createContext<FiltersModalState>({} as FiltersModalState);

export function FiltersModalContextProvider({ children }: { children: React.ReactNode }) {
    const memorizedChildren = useMemo(() => children, [children]);
    const [openFiltersModal, setOpenFiltersModal] = useState(false);
    const [filtersPostData, setFiltersPostData] = useState<Object | undefined>(undefined);
    const [filtersMetadata, setFiltersMetadata] = useState<FiltersData | undefined>(undefined);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [activeIndex, setActiveIndex] = useState({});

    return (
        <FiltersModalContext.Provider
            value={{
                openFiltersModal: openFiltersModal,
                setOpenFiltersModal: setOpenFiltersModal,
                filtersPostData: filtersPostData,
                setFiltersPostData: setFiltersPostData,
                filtersMetadata: filtersMetadata,
                setFiltersMetadata: setFiltersMetadata,
                activeIndex: activeIndex,
                setActiveIndex: setActiveIndex,
                searchTerm: searchTerm,
                setSearchTerm: setSearchTerm,
            }}>
            {memorizedChildren}
        </FiltersModalContext.Provider>
    );
}
