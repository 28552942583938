import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { FiltersModalContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { useContext } from 'react';

export function FiltersModalOpenButton(props: { cardName: string; manageFiltersButtonText: string }) {
    const { setOpenFiltersModal } = useContext(FiltersModalContext);
    return (
        <RoundedTextButton
            key='ScreenerFilterButton'
            theme={roundedTextButtonThemeV2BorderRadius4}
            buttonText={props.manageFiltersButtonText}
            onClickCallback={() => {
                globalThis.analytics?.registerAction?.({
                    action: `click on ${props.manageFiltersButtonText}`,
                    cardName: props.cardName,
                });
                setOpenFiltersModal(true);
            }}
        />
    );
}
