import FormVirtualizeAutocomplete from '@cfra-nextgen-frontend/shared/src/components/Form/FormVirtualizeAutocomplete';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { StyledFormLabel } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { Components, Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import {
    getFilterLabel,
    stackLabelWithContainerProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/shared';
import { FilterProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/types';
import {
    componentToFilterDivider,
    replaceAllDotsWithTabs,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { Box, Chip } from '@mui/material';
import * as React from 'react';
import { useMemo } from 'react';
import { getHookFormValidationRules } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';

type ScreenerFormVirtualizeAutocompleteOutsideChipsProps = {
    defaultInputLabel?: string;
} & FilterProps;

const autocompleteSxProps = { minWidth: '275px', maxWidth: '275px', maxHeight: '30px' };
const childrenContainerStyles = { display: 'block' };
const renderTags = () => null;
const formHelperTextProps = {
    style: {
        fontSize: '10px',
    },
};

const getExternalChips =
    (title: string) =>
    ({ value, onChange }: { value: Array<Item>; onChange: (value: Array<Item>) => void }) => {
        const onDelete = (key: number) => () => onChange(value.filter((item) => item.key !== key));

        return (
            <Box sx={{ width: '100%', paddingTop: '40px' }}>
                {value.length > 0 ? (
                    <StyledFormLabel sx={{ lineHeight: 1.2, paddingTop: '10px', paddingBottom: '10px' }}>
                        {title}
                    </StyledFormLabel>
                ) : null}
                {value
                    .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))
                    .map(({ key, value }: Item) => (
                        <Chip sx={{ margin: '5px' }} key={key} label={value} onDelete={onDelete(key)} />
                    ))}
            </Box>
        );
    };

export const ScreenerFormVirtualizeAutocompleteOutsideChips: React.FC<
    ScreenerFormVirtualizeAutocompleteOutsideChipsProps
> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
    onChangeClearHandler,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const ExternalChips = useMemo(() => getExternalChips(filterMetadata.sub_label || ''), [filterMetadata.sub_label]);
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);

    const filter = (
        <FormVirtualizeAutocomplete
            showSelectionsInLabel
            alwaysShowPlaceholder
            enableCount={false}
            helperText={filterMetadata.caption}
            formHelperTextProps={formHelperTextProps}
            ExternalChips={ExternalChips}
            renderTags={renderTags}
            autocompleteSxProps={autocompleteSxProps}
            label={''}
            control={control}
            name={`${Components.AutocompleteOutsideChips}${componentToFilterDivider}${replaceAllDotsWithTabs(
                filterMetadataKey,
            )}`}
            options={filtersData.data[filterMetadataKey].items}
            placeholder={filterMetadata.placeholder}
            defaultValues={filterMetadata.default_values}
            submitHandler={submitHandler}
            onChangeClearHandler={onChangeClearHandler}
            validationRules={validationRules}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            hide={hide}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            childrenContainerStyles={childrenContainerStyles}
            {...(filterSectionMetadata.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
