import {
    IViewDataFields,
    Metadata,
    ScreenerData,
    Viewdata,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { getValueByPath, getValuesByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { isNumber } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import _ from 'lodash';
import {
    Components,
    CustomValidationTypes,
    CustomValueFormatterTypes,
    FilterMetadata,
    FiltersData,
    Validations,
} from '../types/filters';

export function getSliderValidator(min: number, max: number, setErrorMessage?: (result: string) => void) {
    if (min >= max) {
        throw new Error(`Pointed invalid min or max values for slider validator: min: ${min}, max: ${max}`);
    }

    const validatorFunction = (value: any): string | boolean => {
        const minIsNumber = isNumber(String(value[0]));
        const maxIsNumber = isNumber(String(value[1]));

        let errorMessage = '';

        function errorMin() {
            if (errorMessage.indexOf('min ') === -1) {
                errorMessage += 'min ';
            }
        }

        function errorMax() {
            if (errorMessage.indexOf('max ') === -1) {
                errorMessage += 'max ';
            }
        }

        if (!minIsNumber) {
            errorMin();
        }

        if (!maxIsNumber) {
            errorMax();
        }

        if (parseFloat(value[0]) < min || parseFloat(value[0]) > max) {
            errorMin();
        }

        if (parseFloat(value[1]) > max || parseFloat(value[0]) < min) {
            errorMax();
        }

        if (parseFloat(value[0]) > parseFloat(value[1])) {
            errorMin();
            errorMax();
        }

        setErrorMessage?.(errorMessage);

        return errorMessage || true;
    };

    return validatorFunction;
}

export const hookFormValidationKeys: string[] = ['required', 'maxLength'];
export const hookFormCustomValidation: Record<string, any> = {
    allow_duplicates: validateDuplicate,
    email: validateEmail,
};

function validateDuplicate(fieldName: string, existingList: string[], ignoreList: string[], errorMessage: string) {
    const message = errorMessage || `${fieldName} already exists.`;

    return {
        duplicate: (fieldValue: string) => {
            if (ignoreList.map((str) => str?.toLocaleLowerCase()).includes(fieldValue.toLocaleLowerCase())) {
                return true;
            }

            return (
                !existingList.map((str) => str?.toLocaleLowerCase()).includes(fieldValue.toLocaleLowerCase()) || message
            );
        },
    };
}

function validateEmail(errorMessage: string) {
    const emailPattern = /\S+@\S+\.\S+/;
    const message = errorMessage || `Email is invalid.`;
    return {
        pattern: {
            value: emailPattern,
            message,
        },
    };
}

export function getHookFormValidationRules(
    filtersData: FiltersData,
    filterMetadataKey: string,
    parentSectionKey: string,
) {
    let validationRules: any = {};
    let customValidation: any = {};
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const { validations } = filterMetadata;

    const combinedValidations: Validations = {
        ...validations?.default,
        ...validations?.sections?.[parentSectionKey],
    };

    if (!validations || typeof validations !== 'object') {
        return validationRules;
    }

    const customValidationKeys = Object.keys(hookFormCustomValidation);

    for (const [validationKey, validationValue] of Object.entries(combinedValidations)) {
        if (hookFormValidationKeys.includes(validationKey)) {
            validationRules[validationKey] = validationValue;
        } else if (customValidationKeys.includes(validationKey)) {
            let customValidationRule = {};

            // handle custom validations
            if (validationKey === CustomValidationTypes.AllowDuplicates && validationValue?.value === false) {
                let options: string[] = [];
                if (filterMetadata.component === Components.AutoCompleteFreeSolo) {
                    options = filtersData.data?.[filterMetadataKey].items?.map((o) => o.value);
                }
                const label = filterMetadata.label || filterMetadata.item_metadata.label;
                customValidationRule = hookFormCustomValidation[validationKey](
                    label,
                    options,
                    [filterMetadata.default_value],
                    validationValue?.message,
                );
            } else if (validationKey === CustomValidationTypes.Email && validationValue?.value === true) {
                validationRules = {
                    ...validationRules,
                    ...hookFormCustomValidation[validationKey](validationValue?.message),
                };
            }

            customValidation = {
                ...customValidation,
                ...customValidationRule,
            };
        }
    }

    if (!_.isEmpty(customValidation)) {
        validationRules['validate'] = customValidation;
    }

    return validationRules;
}

export function filtersCustomValueFormatter({
    formatterName,
    filterMetadata,
    screenerData,
}: {
    formatterName: string;
    filterMetadata: FilterMetadata;
    screenerData: ScreenerData;
}) {
    switch (formatterName) {
        case CustomValueFormatterTypes.IsUserEnabled:
            const sourceFields = filterMetadata?.item_metadata?.source_fields || [];

            if (sourceFields.length !== 2) {
                throw new Error(`Missing source fields for ${CustomValueFormatterTypes.IsUserEnabled}`);
            }

            const fieldValues = sourceFields.map((sourceField) => {
                return getFieldValueBySourceField(screenerData, sourceField);
            });

            const [enabledDate, disabledDate] = fieldValues;

            const currentDate = new Date();
            const isEnabled =
                !!enabledDate &&
                new Date(enabledDate) <= currentDate &&
                (!!!disabledDate || currentDate <= new Date(disabledDate));

            return isEnabled;
        default:
            return undefined;
    }
}

export function getFieldMetaBySourceField(fieldMetadata: Metadata, sourceField: string[] | string) {
    const sourceFields = !Array.isArray(sourceField) ? [sourceField] : sourceField;

    return fieldMetadata.fields.filter((fieldMeta) => {
        const metaValue = Object.values(fieldMeta)[0];
        return sourceFields.includes(`${metaValue?.schema_name}.${metaValue?.source_field}`);
    });
}

export function getFieldValueBySourceField(screenerData: ScreenerData, sourceField: string) {
    const fieldMeta = screenerData._metadata.fields.find((fieldMeta) => {
        const metaValue = Object.values(fieldMeta)[0];
        return `${metaValue.schema_name}.${metaValue.source_field}` === sourceField;
    });

    if (fieldMeta) {
        const fieldPath = Object.keys(fieldMeta)[0];
        return getValueByFieldKey(screenerData, fieldPath);
    }
}

export function getValueByFieldKey(screenerData: ScreenerData, fieldPath: string) {
    const { results, _viewdata } = screenerData;
    const sourceData = results['data'][0];

    const fieldViewData = getFieldViewData(_viewdata, fieldPath);
    if (isListField(fieldPath, fieldViewData)) {
        return getValuesByPath(sourceData, fieldPath);
    }
    return getValueByPath(sourceData, fieldPath);
}

export function updateMetadataUrlLinkParams(filter_item_metadata: any, screenerData: ScreenerData) {
    const item_metadata = _.cloneDeep(filter_item_metadata);
    const params_source_field = item_metadata['url_link']['params_source_field'] || {};
    let paramsData: Record<string, any>[] = [{}];
    Object.keys(params_source_field).forEach((paramKey) => {
        if (params_source_field[paramKey]) {
            const value = getFieldValueBySourceField(screenerData, params_source_field[paramKey]);
            if (Array.isArray(value)) {
                value.forEach((v, i) => {
                    paramsData[i] = { ...paramsData[i], [paramKey]: v };
                });
            } else {
                paramsData = paramsData.map((obj) => ({ ...obj, [paramKey]: value }));
            }
        }
    });

    item_metadata['url_link']['params'] = paramsData;
    return item_metadata;
}

export function isListField(key: string, fieldViewMeta?: IViewDataFields) {
    if (!fieldViewMeta) {
        return false;
    }
    return fieldViewMeta[key]?.cell_renderer_params?.map((param) => param.component === 'list').some((value) => value);
}

export function getFieldViewData(viewData: Viewdata, key: string) {
    return viewData.fields.find((field) => {
        return Object.keys(field)[0] === key;
    });
}
