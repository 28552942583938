import { ScreenerAnalyticsServiceContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import {
    SavedItemOperations,
    getSelectedItem,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import {
    ActionTypes,
    ScreenerView,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/types';
import {
    extendUserFieldsData,
    transformToScreenerFieldsUserData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/savedViews';
import { saveCustomView, updateCustomView } from 'features/etfScreener/api/customViews';
import { etfScreenerCardName } from 'features/etfScreener/components/ScreenerHome';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SaveModal } from './SaveModal';

const getTitle = (nameOverlayMode: string | boolean) => {
    switch (nameOverlayMode) {
        case 'save':
            return 'Save Custom View';
        case 'rename':
            return 'Rename View';
    }
};

export function SaveViewModal(props: { existingViews: Array<string> }) {
    const [saveView, setSaveView] = useState<ScreenerView | undefined>();
    const {
        customViewEditorState: { nameOverlayMode },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);
    const {
        screenerViewState: { screenerSaveView, screenerActiveView, allFieldsData },
        screenerViewActionDispatcher,
    } = useContext(ScreenerViewContext);
    const { registerAction } = useContext(ScreenerAnalyticsServiceContext);

    const saveViewResult = saveCustomView({
        requestBody: {
            name: saveView?.label,
            value: transformToScreenerFieldsUserData(saveView?.fieldsData || []),
        },
        config: {
            enabled: saveView !== undefined && nameOverlayMode === 'save',
        },
    });

    const modalTitle = useMemo(() => getTitle(nameOverlayMode), [nameOverlayMode]);

    // handle save view
    useEffect(() => {
        if (
            saveViewResult &&
            !saveViewResult.isLoading &&
            saveViewResult.data &&
            saveViewResult.data.data &&
            allFieldsData
        ) {
            const screenerViewActionDispatcherTypes: Array<ActionTypes> = [
                'RemoveScreenerSaveView',
                'SetRefetchAllPending',
                'SetScreenerActiveView',
            ];
            const fieldsData = extendUserFieldsData({
                allFieldsData,
                userFieldsData: saveViewResult.data.data.value,
            });
            const analyticsOrderedColumns = fieldsData
                .map((field) => [field.viewData.order, `${field.value} : ${field.key} : ${field.viewData.order}`])
                .sort((a, b) => (a[0] as number) - (b[0] as number))
                .map((item) => item[1]) as Array<string>;

            registerAction({
                action: `save ${saveView?.isCurrentView ? 'current' : 'custom'} view : ${getSelectedItem(
                    saveViewResult.data.data,
                )}`,
                cardName: `${etfScreenerCardName} : ${modalTitle}`,
                savedItemName: saveViewResult.data.data.name,
                savedItemId: saveViewResult.data.data.id,
                savedItemOperation: SavedItemOperations.SaveView,
                savedItemLength: analyticsOrderedColumns.length,
                list3: analyticsOrderedColumns,
            });

            if (saveView?.isCurrentView) {
                screenerViewActionDispatcherTypes.push('RemoveScreenerCurrentView');
            }
            setSaveView(undefined);
            screenerViewActionDispatcher({
                type: screenerViewActionDispatcherTypes,
                newState: {
                    screenerActiveView: {
                        key: 'custom',
                        label: saveViewResult.data.data.name,
                        fieldsData: fieldsData,
                        savedItemId: saveViewResult.data.data.id,
                    },
                },
            });
            customViewEditorStateDispatcher({
                type: ['CloseNameViewModal', 'CloseCustomViewEditor', 'SetResetColumnsPending'],
            });
        }
    }, [
        saveViewResult,
        customViewEditorStateDispatcher,
        screenerViewActionDispatcher,
        saveView?.isCurrentView,
        allFieldsData,
        registerAction,
        modalTitle,
    ]);

    const renameViewResult = updateCustomView({
        savedItems: saveView?.savedItemId,
        requestBody: {
            name: saveView?.label,
        },
        config: {
            enabled: saveView !== undefined && nameOverlayMode === 'rename',
        },
    });

    // handle rename view
    useEffect(() => {
        if (
            renameViewResult &&
            !renameViewResult.isLoading &&
            renameViewResult.data &&
            renameViewResult.data.data &&
            allFieldsData
        ) {
            setSaveView(undefined);
            // remove save view state and refetch renamed tab data
            const actionTypes: Array<ActionTypes> = ['RemoveScreenerSaveView', 'SetRefetchPendingForSpecificView'];
            // set new active view only if rename active view
            if (screenerActiveView?.label === screenerSaveView?.label) {
                actionTypes.push('SetScreenerActiveView');
            }
            screenerViewActionDispatcher({
                type: actionTypes,
                newState: {
                    refetchPendingType: renameViewResult.data.data.id,
                    screenerActiveView: {
                        // will apply only if SetScreenerActiveView in actionTypes
                        key: 'custom',
                        label: renameViewResult.data.data.name,
                        fieldsData: extendUserFieldsData({
                            allFieldsData,
                            userFieldsData: renameViewResult.data.data.value,
                        }),
                        savedItemId: renameViewResult.data.data.id,
                    },
                },
            });
            customViewEditorStateDispatcher({
                type: ['CloseNameViewModal'],
            });
        }
    }, [
        renameViewResult,
        customViewEditorStateDispatcher,
        screenerViewActionDispatcher,
        screenerActiveView?.label,
        screenerSaveView?.label,
        allFieldsData,
    ]);

    if (!screenerSaveView) {
        return null;
    }

    return (
        <SaveModal
            title={modalTitle}
            value={screenerSaveView?.label}
            openModal={Boolean(nameOverlayMode)}
            callbackOnClose={() => {
                customViewEditorStateDispatcher({ type: 'CloseNameViewModal' });
                screenerViewActionDispatcher({ type: 'RemoveScreenerSaveView' });
            }}
            saveType='Custom View'
            onPositiveAction={(data) => {
                setSaveView({
                    ...screenerSaveView,
                    label: data,
                });
            }}
            existingNames={props.existingViews}
            positiveBtnText={nameOverlayMode === 'rename' ? 'Update' : undefined}
        />
    );
}
