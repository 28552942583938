import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { LayoutProps } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { Box } from '@mui/material';
import React from 'react';
import { ContainerProps } from './types';
import { ETFInfo } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFInfo/ETFInfo';
import { modalZIndex } from '@cfra-nextgen-frontend/shared/src/components/ETFModal';

export const FiltersSection: React.FC<ContainerProps> = (props) => {
    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'auto',
                    paddingBottom: props.isSearchMode ? '0px' : '3px',
                    ...scrollbarThemeV2,
                }}>
                {React.Children.toArray(
                    props.sectionsWithChildren?.map((sectionWithChildren, index) => {
                        const hide = sectionWithChildren.children.every((child: any) => child.props.hide === true);
                        const section = sectionWithChildren.section;
                        const section_styles = section.styles;
                        const showSectionTitleValue = section.show_section_title;
                        const showSectionTitle = showSectionTitleValue === undefined || showSectionTitleValue === true;
                        const informationModal = sectionWithChildren.informationModal;

                        return (
                            <Grid
                                item
                                container
                                sx={{
                                    paddingTop: props.isSearchMode ? '0px' : '12px',
                                    display: hide ? 'none' : 'flex',
                                }}
                                key={index}
                                {...sectionWithChildren.section.layout}>
                                {showSectionTitle && (
                                    <Grid display={'flex'} flexDirection={'row'}>
                                        <ETFCard.ItemHeader
                                            sx={{
                                                width: '100%',
                                                paddingBottom: '12px',
                                                paddingLeft: '30px',
                                                boxShadow: 'none',
                                                fontSize: '14px',
                                                color: '#0B2958',
                                                borderBottom: '1px solid #EDF0F6',
                                                ...section_styles?.title,
                                            }}>
                                            {sectionWithChildren.section.name}
                                        </ETFCard.ItemHeader>
                                        {informationModal &&
                                            <Box sx={{ marginLeft: '5px', paddingBottom: '2px'}}>
                                                <ETFInfo
                                                    title={informationModal.title}
                                                    description={informationModal.information}
                                                    buttonFontSize={16}
                                                    zIndex={(modalZIndex + 1000)}
                                                    modalBoxStyles={{
                                                        maxHeight: '78.7%',
                                                        overflowY: 'auto',
                                                        ...scrollbarThemeV2
                                                    }}
                                                    descriptionStyle={{
                                                        boxShadow: 'none'
                                                    }}
                                                    titleStyle={{
                                                        boxShadow: 'none'
                                                    }}
                                                />
                                            </Box>}
                                    </Grid>
                                )}
                                <Grid item container xs={12} className='setBorder'>
                                    {sectionWithChildren.children}
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            borderBottom: '1px solid #EDF0F6',
                                            marginTop: '-1px',
                                            ...section_styles?.block_below_children,
                                        }}></Grid>
                                </Grid>
                            </Grid>
                        );
                    }),
                )}
            </Box>
        </>
    );
};

type FiltersContainerProps = {
    children: React.ReactNode;
} & LayoutProps;

export const FiltersContainer: React.FC<FiltersContainerProps> = ({ children, ...rest }) => {
    return (
        <>
            <Grid item {...rest}>
                {children}
            </Grid>
            <Grid item xs={12} sx={{ borderBottom: '1px solid #EDF0F6', marginTop: '-1px' }}></Grid>
        </>
    );
};
