import { ETFCloseButton, Layout } from '@cfra-nextgen-frontend/shared/src';
import { TableExportMenusProps } from '@cfra-nextgen-frontend/shared/src/components/Chart/types';
import { MaxPageWidthString, fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { SxProps, styled } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';

export const modalZIndex = 1900;

const ModalBox = styled(Layout.Grid, {
    name: 'MuiModalBox',
    slot: 'Root',
    // We are specifying here how the styleOverrides are being applied based on props
    overridesResolver: (props, styles) => [styles.root],
})(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none', // fix black border around modal on press shift
    overflowY: 'auto',
    maxHeight: '90%',
    borderBottom: 1,
    borderColor: '#EAEAEA',
    backgroundColor: '#fff',
    maxWidth: MaxPageWidthString,
    padding: '36px',
    width: '95%',
    borderRadius: '5px',
    zIndex: modalZIndex,
}));

export const ItemHeader = styled(Paper)(({ theme }) => ({
    fontSize: '38px',
    color: '#002B5A',
    lineHeight: '1',
    paddingBottom: '36px',
    fontFamily: fontFamilies.GraphikMedium,
}));

export const ItemDescription = styled(Paper)(({ theme }) => ({
    fontSize: '15px',
    color: '#57626a',
    fontFamily: fontFamilies.GraphikRegular,
    lineHeight: '26px',
}));

type Props = {
    title?: string;
    titleStyle?: SxProps;
    description?: string | React.ReactNode;
    descriptionStyle?: SxProps;
    titleAndDescriptionContainerStyle?: SxProps;
    titleAndDescriptionContainerXs?: number | boolean;
    closeButtonFontSize?: number;
    children?: React.ReactNode;
    modalBoxStyles?: SxProps;
    getOpenComponent?: (handleOpen: () => void) => React.ReactElement;
    exportCallback?: () => void;
    TableExportMenus?: (inputProps: TableExportMenusProps) => JSX.Element;
    analyticsExportCallback?: (type: string) => void;
    analyticsOpenModalCallback?: () => void;
    callbackOnClose?: () => void;
    externalOpenModal?: boolean;
    keepContentOnClose?: boolean;
    showCloseButton?: boolean;
    zIndex?: number;
};

export default function ETFModal({
    title,
    titleStyle,
    description,
    descriptionStyle,
    titleAndDescriptionContainerStyle,
    titleAndDescriptionContainerXs = 12,
    closeButtonFontSize,
    children,
    modalBoxStyles,
    getOpenComponent,
    exportCallback,
    TableExportMenus,
    analyticsExportCallback,
    analyticsOpenModalCallback,
    callbackOnClose,
    externalOpenModal,
    keepContentOnClose,
    showCloseButton = true,
    zIndex,
}: Props) {
    const [openModal, setOpenModal] = useState(getOpenComponent ? false : true);
    const getOpenModal = () => (externalOpenModal === undefined ? openModal : externalOpenModal);

    if (getOpenModal()) {
        analyticsOpenModalCallback?.();
    }

    const handleClose = () => {
        setOpenModal(false);
        if (callbackOnClose) {
            callbackOnClose();
        }
    };

    const OpenComponent = useMemo(() => getOpenComponent?.(() => setOpenModal(true)), [getOpenComponent]);

    const modalJSX = (
        <Modal
            open={getOpenModal()}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            style={{ zIndex: zIndex || 1600 }}
            BackdropProps={{ style: { backgroundColor: '#002B5A', opacity: 0.33 } }}
            keepMounted={keepContentOnClose}>
            {/* Need cfra-etf-modal class name to apply transform-style: preserve-3d to all elements inside, this fix issue modal content is blurry */}
            <ModalBox container alignItems='baseline' sx={modalBoxStyles}>
                <Layout.Grid item container xs={titleAndDescriptionContainerXs} sx={titleAndDescriptionContainerStyle}>
                    <Layout.Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {title ? <ItemHeader sx={titleStyle}>{title}</ItemHeader> : null}
                        <Layout.Grid item sx={{ display: 'flex' }}>
                            {exportCallback && TableExportMenus ? (
                                <TableExportMenus
                                    exportCallback={exportCallback}
                                    analyticsCallback={analyticsExportCallback}
                                />
                            ) : null}
                            {showCloseButton ? (
                                <ETFCloseButton
                                    onClick={handleClose}
                                    buttonFontSize={closeButtonFontSize}
                                    sx={{ paddingLeft: '16px' }}
                                />
                            ) : null}
                        </Layout.Grid>
                    </Layout.Grid>
                    {description ? (
                        <Layout.Grid item xs={12}>
                            <ItemDescription sx={descriptionStyle}>{description}</ItemDescription>
                        </Layout.Grid>
                    ) : null}
                </Layout.Grid>
                {children}
            </ModalBox>
        </Modal>
    );

    if (keepContentOnClose) {
        return (
            <>
                {OpenComponent}
                {modalJSX}
            </>
        );
    }

    return (
        <>
            {OpenComponent}
            {getOpenModal() ? modalJSX : null}
        </>
    );
}
