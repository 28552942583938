import FormSwitch from '@cfra-nextgen-frontend/shared/src/components/Form/FormSwitch';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { getFilterLabel } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/filters/shared';
import { componentToFilterDivider, replaceAllDotsWithTabs } from '../utils';
import { FilterProps } from './types';

export default function ScreenerFormSwitch({
    filtersData,
    filterMetadataKey,
    control,
    layoutProps,
    getValues,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
}: FilterProps) {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    const filter = (
        <FormSwitch
            name={`${Components.Switch}${componentToFilterDivider}${replaceAllDotsWithTabs(filterMetadataKey)}`}
            control={control}
            getValues={getValues}
            submitHandler={submitHandler}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer label={label} layoutProps={layoutProps} hide={hide} disableStacking>
            {filter}
        </FormLabelWithContainer>
    );
}
