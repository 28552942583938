import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { ETFCard, defaultEmptyCardText } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import FormAutocompletePicklist from '@cfra-nextgen-frontend/shared/src/components/Form/FormAutocompletePicklist';
import FormDraggablePicklist from '@cfra-nextgen-frontend/shared/src/components/Form/FormDraggablePicklist';
import {
    Components,
    StringKeyValueItemWithData,
} from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { CustomViewEditorContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/CustomViewEditorContext';
import { ActionTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/customViewEditor/types';
import {
    componentToFilterDivider,
    getViewDataForColumn,
    handleDefaultSelected,
    sortByOrder,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { ScreenerViewContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerViewContext/Context';
import { ScreenerEtfData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { screenerCurrentViewLabel } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/constants';
import {
    extendUserFieldsData,
    transformToScreenerFieldsUserData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/savedViews';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { areEqualArrays } from '@cfra-nextgen-frontend/shared/src/utils';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseQueryResult } from 'react-query';
import { updateCustomView } from '../api/customViews';
import { getScreenerData } from '../api/screener';

type CustomViewEditorFormProps = {
    analyticsCardName: string;
};

const gridSpacing = 28;

function Column(props: { children: React.ReactNode; sx?: SxProps }) {
    return (
        <Grid
            item
            sx={{
                height: '100%',
                width: `calc(100%/2 - ${gridSpacing / 2}px)`,
                ...props.sx,
            }}>
            {props.children}
        </Grid>
    );
}

const rightColumnLabel = 'Active Columns';
const rightColumnFormElementName = `${Components.DraggablePicklist}${componentToFilterDivider}${rightColumnLabel}`;
const leftColumnLabel = 'Add Columns';
const leftColumnFormElementName = `${Components.AutocompletePicklist}${componentToFilterDivider}${leftColumnLabel}`;
const viewNowButtonText = 'View Now';
const saveViewButtonText = 'Save View';
const cancelButtonText = 'Cancel';
const updateViewButtonText = 'Update View';

export function CustomViewEditorForm({ analyticsCardName }: CustomViewEditorFormProps) {
    const [updateView, setUpdateView] = useState<Array<StringKeyValueItemWithData> | undefined>(undefined);
    const {
        screenerViewState: { allFieldsData },
        screenerViewActionDispatcher,
    } = useContext(ScreenerViewContext);
    const {
        customViewEditorState: { isDirtyRightColumn, resetColumnsState, screenerUpdateView },
        customViewEditorStateDispatcher,
    } = useContext(CustomViewEditorContext);
    const { control, getValues, handleSubmit, setValue } = useForm({
        reValidateMode: 'onSubmit',
    });

    // get all screener fields definitions
    const columnsDataUseQueryResult = getScreenerData({
        view: 'fields',
        includeData: false,
        includeMetadata: true,
    }) as UseQueryResult<ScreenerEtfData>;

    const updateCustomViewRequestValue = useMemo(
        () => transformToScreenerFieldsUserData(updateView || []),
        [updateView],
    );

    // update custom view fields
    const updateCustomViewFieldsResult = updateCustomView({
        savedItems: screenerUpdateView?.savedItemId,
        requestBody: {
            value: updateCustomViewRequestValue,
        },
        config: {
            enabled: Boolean(updateView),
        },
    });

    // aggregate all screener fields definitions to Array<StringKeyValueItemWithData>
    const options: Array<StringKeyValueItemWithData> = useMemo(() => {
        const _options: Array<StringKeyValueItemWithData> = [];
        if (!columnsDataUseQueryResult.data) {
            return _options;
        }
        const columnsData = columnsDataUseQueryResult.data;

        columnsData._metadata.fields.forEach((fieldObject) => {
            Object.keys(fieldObject).forEach((key) => {
                const viewData = getViewDataForColumn(key, columnsData._viewdata.fields);
                _options.push({
                    key: key,
                    value: fieldObject[key].label,
                    viewData: viewData,
                    metaData: fieldObject[key],
                });
            });
        });

        return sortByOrder(_options);
    }, [columnsDataUseQueryResult.data]);

    // pass aggregated screener definitions to ScreenerViews component
    useEffect(() => {
        if (options.length > 0) {
            screenerViewActionDispatcher({
                type: 'SetAllFieldsData',
                newState: {
                    allFieldsData: options,
                },
            });
        }
    }, [options, screenerViewActionDispatcher]);

    const defaultRightColumnOptions: Array<StringKeyValueItemWithData> = useMemo(() => {
        // get values with default_selected: true, sorted by view data order, and replace the order with new values starting from 1
        return handleDefaultSelected('extract', options).map((value, index) => ({
            ...value,
            viewData: { ...value.viewData, order: index + 1 },
        }));
    }, [options]);

    const submitHandler: (fieldsData: Array<StringKeyValueItemWithData>) => void = useCallback(
        (fieldsData) =>
            handleSubmit(() => {
                if (screenerUpdateView && !screenerUpdateView.isCurrentView) {
                    setUpdateView(fieldsData);
                    return;
                }

                screenerViewActionDispatcher({
                    type: 'SetScreenerSaveView',
                    newState: {
                        screenerSaveView: {
                            key: 'custom',
                            fieldsData: fieldsData,
                            isCurrentView: Boolean(screenerUpdateView?.isCurrentView),
                        },
                    },
                });
                customViewEditorStateDispatcher({
                    type: 'OpenNameViewModalInSaveMode',
                });
            })(),
        [
            handleSubmit,
            customViewEditorStateDispatcher,
            screenerViewActionDispatcher,
            screenerUpdateView,
            setUpdateView,
        ],
    );

    const resetCustomViewEditor: (postActions?: Array<ActionTypes>) => void = useCallback(
        (postActions) => {
            customViewEditorStateDispatcher({
                type: [
                    'SetResetColumnsDone',
                    'SetRightColumnClean',
                    'RemoveScreenerUpdateView',
                    ...(postActions || []),
                ],
            });
            setValue(leftColumnFormElementName, []);
            setValue(rightColumnFormElementName, defaultRightColumnOptions);
        },
        [customViewEditorStateDispatcher, defaultRightColumnOptions, setValue],
    );

    useEffect(() => {
        if (
            updateCustomViewFieldsResult &&
            updateCustomViewFieldsResult.data &&
            updateCustomViewFieldsResult.data.data &&
            allFieldsData
        ) {
            setUpdateView(undefined);
            resetCustomViewEditor(['CloseCustomViewEditor']);
            screenerViewActionDispatcher({
                type: ['SetRefetchPendingForSpecificView', 'SetScreenerActiveView'],
                newState: {
                    refetchPendingType: screenerUpdateView?.savedItemId,
                    screenerActiveView: {
                        key: 'custom',
                        label: updateCustomViewFieldsResult.data.data.name,
                        fieldsData: extendUserFieldsData({
                            allFieldsData,
                            userFieldsData: updateCustomViewFieldsResult.data.data.value,
                        }),
                        savedItemId: updateCustomViewFieldsResult.data.data.id,
                    },
                },
            });
        }
    }, [
        resetCustomViewEditor,
        screenerUpdateView?.savedItemId,
        screenerViewActionDispatcher,
        updateCustomViewFieldsResult,
        allFieldsData,
    ]);

    // handle reset both columns
    useEffect(() => {
        if (resetColumnsState) {
            resetCustomViewEditor();
        }
    }, [resetColumnsState, resetCustomViewEditor]);

    const handleDirtyStateForUpdateView = useCallback(
        (newValues: Array<StringKeyValueItemWithData>) => {
            if (!screenerUpdateView?.fieldsData) {
                return;
            }

            const startingKeys = sortByOrder(handleDefaultSelected('exclude', screenerUpdateView.fieldsData)).map(
                (item) => item.key,
            );

            const newKeys = newValues.map((item) => item.key);

            const isDirtyRightColumnLocal = !areEqualArrays(startingKeys, newKeys);

            if (isDirtyRightColumnLocal && !isDirtyRightColumn) {
                customViewEditorStateDispatcher({
                    type: 'SetRightColumnDirty',
                });
            }

            if (!isDirtyRightColumnLocal && isDirtyRightColumn) {
                customViewEditorStateDispatcher({
                    type: 'SetRightColumnClean',
                });
            }
        },
        [customViewEditorStateDispatcher, isDirtyRightColumn, screenerUpdateView?.fieldsData],
    );

    const leftColumn: React.ReactNode | null = useMemo(() => {
        return (
            <FormAutocompletePicklist
                label={leftColumnLabel}
                control={control}
                name={leftColumnFormElementName}
                options={handleDefaultSelected('exclude', options)}
                placeholder={'Search Filters'}
                onChange={(leftColumnValues: Array<StringKeyValueItemWithData>) => {
                    const oldRightColumnValues = getValues(
                        rightColumnFormElementName,
                    ) as Array<StringKeyValueItemWithData>;

                    const theGreatestRightColumnOrder = Math.max(
                        ...oldRightColumnValues.map((value) => value.viewData.order),
                    );

                    const newRightColumnValues = sortByOrder(
                        leftColumnValues.map((value) => {
                            const oldOrder =
                                oldRightColumnValues.find((oldValue) => oldValue.key === value.key)?.viewData.order ||
                                theGreatestRightColumnOrder + 1;
                            return { ...value, viewData: { ...value.viewData, order: oldOrder } };
                        }),
                    );

                    setValue(rightColumnFormElementName, defaultRightColumnOptions.concat(newRightColumnValues));

                    if (!screenerUpdateView?.fieldsData) {
                        if (newRightColumnValues.length > 0 && !isDirtyRightColumn) {
                            customViewEditorStateDispatcher({
                                type: 'SetRightColumnDirty',
                            });
                        }

                        if (newRightColumnValues.length === 0 && isDirtyRightColumn) {
                            customViewEditorStateDispatcher({
                                type: 'SetRightColumnClean',
                            });
                        }
                        return;
                    }

                    handleDirtyStateForUpdateView(newRightColumnValues);
                }}
            />
        );
    }, [
        control,
        options,
        defaultRightColumnOptions,
        getValues,
        setValue,
        isDirtyRightColumn,
        customViewEditorStateDispatcher,
        screenerUpdateView?.fieldsData,
        handleDirtyStateForUpdateView,
    ]);

    const rightColumn: React.ReactNode | null = useMemo(() => {
        return (
            <FormDraggablePicklist
                label={rightColumnLabel}
                control={control}
                name={rightColumnFormElementName}
                defaultValues={defaultRightColumnOptions}
                onChange={(newValue: Array<StringKeyValueItemWithData>) => {
                    const newValuesDefaultExcluded = handleDefaultSelected('exclude', newValue);
                    setValue(leftColumnFormElementName, newValuesDefaultExcluded);

                    if (!screenerUpdateView?.fieldsData) {
                        if (newValuesDefaultExcluded.length === 0 && isDirtyRightColumn) {
                            customViewEditorStateDispatcher({
                                type: 'SetRightColumnClean',
                            });
                        }
                        return;
                    }

                    handleDirtyStateForUpdateView(sortByOrder(newValuesDefaultExcluded));
                }}
            />
        );
    }, [
        control,
        defaultRightColumnOptions,
        setValue,
        customViewEditorStateDispatcher,
        isDirtyRightColumn,
        handleDirtyStateForUpdateView,
        screenerUpdateView?.fieldsData,
    ]);

    // apply selections for edit view
    useEffect(() => {
        if (screenerUpdateView?.fieldsData) {
            const orderedFieldsData = sortByOrder(screenerUpdateView.fieldsData);
            const fieldsDataWithoutDefaultSelected = handleDefaultSelected('exclude', orderedFieldsData);
            setValue(leftColumnFormElementName, fieldsDataWithoutDefaultSelected);
            setValue(rightColumnFormElementName, orderedFieldsData);

            // open custom view editor only after values are set, to avoid empty selections visible for a second
            customViewEditorStateDispatcher({
                type: ['OpenCustomViewEditor'],
            });
        }
    }, [screenerUpdateView?.fieldsData, customViewEditorStateDispatcher, setValue]);

    if (!columnsDataUseQueryResult.data && columnsDataUseQueryResult.isLoading) {
        return <ETFCard isLoading={columnsDataUseQueryResult.isLoading} />;
    }

    if (
        !columnsDataUseQueryResult.isLoading &&
        !(
            columnsDataUseQueryResult.data?._metadata &&
            columnsDataUseQueryResult.data._metadata.fields &&
            columnsDataUseQueryResult.data._metadata.fields.length > 0
        )
    ) {
        return <>{defaultEmptyCardText}</>;
    }

    const showViewNowAndSaveViewButtons = !screenerUpdateView || screenerUpdateView?.isCurrentView;
    const rightButtonText = showViewNowAndSaveViewButtons ? saveViewButtonText : updateViewButtonText;

    return (
        <div style={{ overflow: 'auto', flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Grid container sx={{ overflow: 'auto', flexGrow: 1, ...scrollbarThemeV2 }}>
                <Column sx={{ marginRight: `${gridSpacing}px` }}>{leftColumn}</Column>
                <Column> {rightColumn}</Column>
            </Grid>
            <Box sx={{ flex: '0 0 auto', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                <Box sx={{ paddingTop: '18px' }}>
                    {showViewNowAndSaveViewButtons && (
                        <RoundedTextButton
                            outlined
                            theme={roundedTextButtonThemeV2}
                            buttonText={viewNowButtonText}
                            onClickCallback={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: `click on ${viewNowButtonText}`,
                                    cardName: analyticsCardName,
                                });
                                if (screenerUpdateView?.isCurrentView || isDirtyRightColumn) {
                                    const values = getValues(
                                        // get right column selections state
                                        rightColumnFormElementName,
                                    ) as Array<StringKeyValueItemWithData>;

                                    const view = {
                                        label: screenerCurrentViewLabel,
                                        key: 'custom',
                                        fieldsData: values,
                                        isCurrentView: true,
                                    };

                                    screenerViewActionDispatcher({
                                        type: ['SetScreenerActiveView', 'SetScreenerCurrentView'],
                                        newState: {
                                            screenerActiveView: view,
                                            screenerCurrentView: view,
                                        },
                                    });
                                    // reset columns directly, to avoid additional re-render due to changing resetColumnsState
                                    resetCustomViewEditor(['CloseCustomViewEditor']);
                                } else {
                                    customViewEditorStateDispatcher({
                                        type: 'OpenNoColumnsSelectedModal',
                                    });
                                }
                            }}
                        />
                    )}
                    {!showViewNowAndSaveViewButtons && (
                        <RoundedTextButton
                            outlined
                            theme={roundedTextButtonThemeV2}
                            buttonText={cancelButtonText}
                            onClickCallback={() => {
                                globalThis.analytics?.registerAction?.({
                                    action: `click on ${cancelButtonText}`,
                                    cardName: analyticsCardName,
                                });
                                resetCustomViewEditor(['CloseCustomViewEditor']);
                            }}
                        />
                    )}
                </Box>
                <Box sx={{ paddingLeft: '15px', paddingTop: '18px' }}>
                    <RoundedTextButton
                        theme={roundedTextButtonThemeV2}
                        disabled={showViewNowAndSaveViewButtons ? false : !isDirtyRightColumn}
                        buttonText={rightButtonText}
                        onClickCallback={() => {
                            globalThis.analytics?.registerAction?.({
                                action: `click on ${rightButtonText}`,
                                cardName: analyticsCardName,
                            });

                            const rightColumnValues = getValues(
                                // get right column selections state
                                rightColumnFormElementName,
                            ) as Array<StringKeyValueItemWithData>;

                            const notZeroSelectedOptions =
                                handleDefaultSelected('exclude', rightColumnValues).length !== 0;

                            if (
                                (isDirtyRightColumn && notZeroSelectedOptions) ||
                                (screenerUpdateView?.isCurrentView && notZeroSelectedOptions)
                            ) {
                                submitHandler(rightColumnValues);
                            } else {
                                customViewEditorStateDispatcher({
                                    type: 'OpenNoColumnsSelectedModal',
                                });
                            }
                        }}
                    />
                </Box>
            </Box>
        </div>
    );
}
