import { ETFCard, Layout, Typography } from '@cfra-nextgen-frontend/shared';
import { tableTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ValueTypes, fontFamilies, fontWeights, formatValue } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, ThemeProvider } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { getDefaultFontStyle } from 'components/Chart/Options';
import { getETFCFRARatings, getEtfDataHoldingsAndExposure } from '../api/etfDetailsData';
import { Rating } from '@cfra-nextgen-frontend/shared/src/components/Rating/Rating';
import { LinearProgressBar } from '@cfra-nextgen-frontend/shared/src/components/Progress/LinearProgressBar';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ReadMore } from '@cfra-nextgen-frontend/shared/src/components/ReadMore/ReadMore';
import { ETFInfo } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFInfo/ETFInfo';
import { SectionKeys, SectionsWithInformation } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { scrollbarThemeV2 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';

export default function CFRARatings({ cfraId }: { cfraId: string }) {

    const cfraRatingsResult = getETFCFRARatings({
        cfraId: cfraId,
        config: {
            refetchOnWindowFocus: false,
        },
    });

    // getting UseQueryResult object with data for etfDataHoldingsAndExposure.current
    const etfDataHoldingsAndExposureQueryResult = getEtfDataHoldingsAndExposure({
        cfraId: cfraId,
        top: 10,
        config: {
            refetchOnWindowFocus: false,
        },
    });

    // show card loading if data still loading
    if (cfraRatingsResult.isLoading && !cfraRatingsResult.data) {
        return <ETFCard.ETFCard isLoading={cfraRatingsResult.isLoading} />;
    }

    const cfraRatingsData = cfraRatingsResult.data;

    const cardName = 'CFRA ETF Ratings';

    // return EmptyCard if no data
    if (
        !(
            cfraRatingsData &&
            cfraRatingsData.stars_rating &&
            cfraRatingsData.cost != null &&
            cfraRatingsData.reward != null &&
            cfraRatingsData.risk != null
        )
    ) {
        return <ETFCard.ETFEmptyCard cardLabel={cardName}></ETFCard.ETFEmptyCard>;
    }

    const ItemSubHeader = styled(ETFCard.ItemHeader)(({ theme }) => ({
        ...getDefaultFontStyle(15),
    }));

    const SubHeder = styled(Paper)(({ theme }) => ({
        fontSize: '15px',
        fontFamily: fontFamilies.GraphikMedium,
        color: '#002B5A',
        paddingBottom: '10px',
    }));

    const Title = styled(Typography)(({ theme }) => ({
        color: '#002B5A',
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '15px',
        lineHeight: '21px'
    }));

    const Value = styled(Typography)(({ theme }) => ({
        color: '#002B5A',
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '15px',
        lineHeight: 1,
        fontWeight: fontWeights.Bold,
        marginLeft: '10px',
        marginTop: '-1px'
    }));

    const subHeader = 'CFRA ETF Ratings apply forward-looking metrics and the unique characteristics of the fund\'s holdings rather than solely relying on historical perfomance';

    const scoreFootnote = 'Scores range from 1 to 100; 100 is most favorable';

    const CommentarySection = ({ commentary }: { commentary: string }) => {
        return (
            <>
                <SubHeder>Commentary</SubHeder>
                <ReadMore text={commentary} modalTitle={'Commentary'} />
            </>
        );
    }

    const componentScores = [
        { text: 'Cost', value: cfraRatingsData.cost, titleLow: 'Most Expensive', titleHigh: 'Least Expensive' },
        { text: 'Reward', value: cfraRatingsData.reward, titleLow: 'Low', titleHigh: 'High' },
        { text: 'Risk', value: cfraRatingsData.risk, titleLow: 'Riskiest', titleHigh: 'Safest' }
    ];

    let percentValue = '';

    if (etfDataHoldingsAndExposureQueryResult?.data?.total_weight_percentage) {
        percentValue = formatValue({
            value: etfDataHoldingsAndExposureQueryResult?.data?.total_weight_percentage,
            formattingType: ValueTypes.Percentage
        }).toString();
    }

    const informationModal = SectionsWithInformation[SectionKeys.CFRARatings];
    return (
        <ThemeProvider theme={tableTheme}>
            <ETFCard.ETFCard containerStyles={{ position: 'relative' }}>
                <Layout.Grid item xs={12} sx={{ paddingLeft: '28px', paddingRight: '28px', justifyContent: 'start' }}>
                    <ETFCard.ItemHeader style={{ paddingBottom: '12px' }}>{cardName}</ETFCard.ItemHeader>
                    <Box sx={{ marginLeft: '5px', paddingBottom: '2px' }}>
                        <ETFInfo
                            title={informationModal.title}
                            description={informationModal.information}
                            buttonFontSize={16}
                            modalBoxStyles={{
                                maxHeight: '78.7%',
                                overflowY: 'auto',
                                ...scrollbarThemeV2
                            }}
                            descriptionStyle={{
                                boxShadow: 'none'
                            }}
                            titleStyle={{
                                boxShadow: 'none'
                            }}
                        />
                    </Box>
                </Layout.Grid>
                <ItemSubHeader style={{ paddingLeft: '28px', paddingRight: '28px', lineHeight: '21px' }}>{subHeader}</ItemSubHeader>
                <Layout.Grid container spacing={4} style={{ paddingLeft: '28px', paddingRight: '28px' }}>
                    <Layout.Grid container item xs={12} md={7}>
                        <Layout.Grid item xs={12} flexDirection={'column'} justifyContent={'flex-start'}>
                            <SubHeder>Stars Rating</SubHeder>
                            <Rating value={Number(cfraRatingsData.stars_rating)} size={'medium'} />
                        </Layout.Grid>
                        <Layout.Grid item xs={12} sx={{
                            display: {
                                xs: 'none',
                                md: 'block'
                            },
                            marginTop: '30px'
                        }}>
                            <CommentarySection commentary={cfraRatingsData.commentary.replace('{percent}', percentValue)} />
                        </Layout.Grid>
                    </Layout.Grid>
                    <Layout.Grid item xs={12} md={5} flexDirection={'column'} display={'block'}
                        sx={{
                            [breakpointsTheme.breakpoints.down('md')]: {
                                marginTop: '20px'
                            }
                        }}>
                        <SubHeder>Rating Scores</SubHeder>
                        {componentScores.map((score, index) => (
                            <Layout.Grid key={index} container flexDirection={'column'} height={'70px'}>
                                <Layout.Grid>
                                    <Title>{score.text}</Title>
                                </Layout.Grid>
                                <Layout.Grid item>
                                    <Layout.Grid item xs={10} display={'block'}>
                                        <LinearProgressBar
                                            value={score.value}
                                            variant={'determinate'}
                                            titlelow={score.titleLow}
                                            titlehigh={score.titleHigh} />
                                    </Layout.Grid>
                                    <Layout.Grid item xs={2}>
                                        <Value>{score.value}</Value>
                                    </Layout.Grid>
                                </Layout.Grid>
                            </Layout.Grid>))
                        }
                        <Box component='div' sx={{
                            fontFamily: fontFamilies.GraphikRegular,
                            color: 'rgb(118, 129, 140)',
                            fontSize: '11px'
                        }}>
                            {scoreFootnote}
                        </Box>
                    </Layout.Grid>
                    <Layout.Grid item xs={12} sx={{
                        display: {
                            xs: 'block',
                            md: 'none'
                        },
                        marginTop: '20px'
                    }}>
                        <CommentarySection commentary={cfraRatingsData.commentary.replace('{percent}', percentValue)} />
                    </Layout.Grid>
                </Layout.Grid>
                <Layout.DataAsOfDate date={cfraRatingsData.ratings_as_of_data} />
            </ETFCard.ETFCard>
        </ThemeProvider>
    );
}
